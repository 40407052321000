import React from "react"
import PayrollBento from "../../../components/body/pages/en-ng/business/payroll-bento"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const PayrollBentoPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/payroll-software"}
      title="Payroll Software | Automate and Streamline Your Payroll with Bento and Kuda | Kuda Business"
      description="Discover the power of Payroll with Bento, a comprehensive payroll software solution. Simplify payroll management, automate tax calculations, and improve compliance with our user-friendly platform"
    />

    <PayrollBento />
  </Layout>
)

export default PayrollBentoPage
